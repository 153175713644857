.RContainer {
    width: 80%;
    border-radius: 20px;
    background-color: #6a6c6c73;
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
    display: inline-block;
    text-align: left;
    vertical-align: top;
    box-shadow: 0 0 23px -4px black;
    margin: 0 20px;
    position:relative;
    padding: 50px 20px 20px 20px;
    
}

.fRow{
    background-color: grey;
}

.formInput{
color:#f1eebff8 !important;
}

.formC{
    margin: 0px 20px 30px !important;

}

.eventItem{
    width: 200px;
    height: 80px;
    text-align: center;
    /* background-color: #f7e6dcf8 !important; */
    padding: 20px !important;
    display: table
}

.eventItem > div{
    width: 100%;
    display: table-cell;
    text-align: center;
    vertical-align: middle;
}

.eventItem:hover{
    box-shadow: 0 0 32px -5px white;
}

.tabContainer {
    /* width: 101%; */
    width: fit-content;
    min-height: 486px;
    border-radius: 20px;
    background-color: #6a6c6c73;
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
    padding: 15px;
    display: inline-block;
    text-align: left;
    vertical-align: top;
    /* margin-top: -5vh; */
    box-shadow: 0 0 23px -4px black;
}

.bg{
    /* background-image: url(/static/media/header.d1cde404.jpg); */
    background-position: center;
    width: 100%;
    overflow: auto;
    /* position: absolute; */
    background-size: cover;
    background-repeat: no-repeat;
}
.bgFilter{
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), black);
    min-height: 100vh;
}
.tabShow{
    display: none;
}
.treeItem{
    border-radius: 17px;
    background-color: #444;
    font-size: 25px !important;
    text-align: center;
    padding: 10px !important;
    margin-bottom: 15px !important;
    box-shadow: 0px 2px 10px 0px #0000004d;

}

.treeItem > div{
    background-color: #444444 !important; 

}
@media only screen and (max-width: 800px) {
    .tabHide{
       display:none;
    }
    .tabShow{
        display:block;
    }
    .tabContainer {
        width:95%
    }
 
}