

.SmallBookingButton{
    border-radius: 50px;
    opacity: 0.7;
    width: 150px;
    height: 30px;
    font-size: 12px;
    border:none;
    background: none;
    outline: none !important;
    color:white;
    box-shadow: none;
}
.SmallBookingButton.selected{
    background-color: rgb(29, 29, 29);


}