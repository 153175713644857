.inpt{
  width: 100%;
  border-radius: 5px;
  border:none;
  padding: 6px;
}

.locationInptOptions{
  background: #05171d;
  padding: 10px;
  z-index: 200;
  position: absolute;
  border-radius: 8px;
  margin-top: 5px;
  display: none;
}

.showResults {
  display: block
}

.locationInptOptions > div{
  padding: 5px 5px;
  border-radius: 8px;
}

.locationInptOptions > div:hover{
  background: white;
  color: #464040
}


.myLocation {

  float: right;
  margin: -27px 4px 0 0;
  position: relative;
  color: #252525 !important;
}

.myLocation:hover {
  color: black !important;
}