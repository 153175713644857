.onOffBtn{
    background-color: white;
    border-radius: 5px;
    height: 34.5px;
    /* width: 100px; */
    text-align: center;
    display: inline-block;
    padding: 6px 12px 7px 6px;
    vertical-align: top;
    color: black;
    margin: 2px 4px 2px 0;
    cursor: pointer;
}


.onBtn{
    background-color: #327543; /* #be9d50; */
    color: white;

}