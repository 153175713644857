.bookingContainer {
    width: 101%;
    /* height: 25%; */
    border-radius: 20px;
    background-color: #6a6c6c73;
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
    padding: 15px;
    display: inline-block;
    text-align: left;
    vertical-align: top;
    /* margin-top: -5vh; */
    box-shadow: 0 0 23px -4px black;
}


.bookingContainer > div:first-child{
    border-bottom:  1px solid rgb(153, 145, 145);
    padding-bottom: 10px;
} 


.bookingContainer > div:nth-child(2){
    padding: 30px 0;
}
.bookingContainer > div:nth-child(2) > div{
    display: inline-block;
}
.bookingContainer > div:nth-child(2) > div:first-child{
    margin-left: 0;
    /* min-width: 50%; */
    width: 100%;
    max-width: 500px;
}


.bookingContainer .searchBtn{
    margin: auto;
    margin-top: -2em;
    position: absolute;
    background-color: #eaeaea;
    border: 3px solid #d6d6d6;
    height: 6em;
    width: 6em;
    border-radius: 50px;
    text-align: center;
    vertical-align: middle;
    align-items: center;
    display: flex;
    align-items: center;
    left: 0;
    right: 0;
}


.bookingContainer .searchBtn > img{
    margin: auto;
    filter: brightness(0);

}


.bookingContainer .searchBtn:hover{
    box-shadow: 0 0 22px -1px gold;
    transition: ease 1s;
    cursor: pointer;
    background-color: gold;
    border: 3px solid #e0cf79;
}





.searchBtnContainer{
    text-align: center;
    display: inline-block;
}

@media only screen and (max-width: 800px) {
    .searchBtnContainer{
        width: 100%;
        text-align: center;
        display: inline-block;
    }
  }