.btn {
    width: 4em;
    height: 4em;
    border-radius: 50px;
    border: 1px solid #c9c9c9;
    text-align: center;
    padding: 3px;
    color: #313131;
    margin: auto;
    outline: none;
    transition: ease 1s;
}

.btn:hover{
    box-shadow: 0 0 32px -5px white;
}