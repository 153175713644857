.RContainer {
    width: 80%;
    border-radius: 20px;
    background-color: #6a6c6c73;
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
    display: inline-block;
    text-align: left;
    vertical-align: top;
    box-shadow: 0 0 23px -4px black;
    margin: 0 20px;
    position:relative;
    padding: 50px 20px;
    
}

.fRow{
    background-color: grey;
}

.formInput{
color:#f1eebff8 !important;
}

.formC{
    margin: 0px 20px 30px !important;

}