.RContainer {
    width: 80%;
    border-radius: 20px;
    background-color: #6a6c6c73;
    -webkit-backdrop-filter: blur(10px);
    /* backdrop-filter: blur(10px); */
    display: inline-block;
    text-align: left;
    vertical-align: top;
    box-shadow: 0 0 23px -4px black;
    margin: 0 20px;
    position:relative;
    padding: 50px 20px 20px 20px;
    margin-top: 30px;
    height: 535px;
    width: 500px;
    max-width: 91%;
}
.dayPrice{
    background: white;
    border-radius: 5px 0 0 5px;
    position: absolute;
    color: black;
    padding: 3px 6px;
    top: 60px;
    right: 20px;
    font-weight: bold;
}
.discountTag{

    background: red;
    border-radius: 5px 0 0 5px;
    position: absolute;
    color: white;
    padding: 3px 6px;
    top: 90px;
    right: 20px;
}
.cardMedia
{
    height: 220px;
    width: 100%;
    border-radius: 10px;
}

.carFooter{
    position: absolute;
    bottom: 20px;
    text-align: center;
    left: 2.5%;
    width: 95%;
}

.specsContainer {
    margin: 5px 0 15px 0 !important;
}
.specsItem{
    color: white;
    border-right: none;
    padding-left: 0;
    font-size: 11px;
    display: inline-block;
    margin-right: 6px;
    
}

.specsItem span{
    vertical-align: middle;
    text-transform: capitalize;

}


.specsItem > *{
    margin-right:2px;
}