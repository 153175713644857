.bigBookingButton{
    min-width: 210px;
    height: 3em;
    color:white;
    margin-right: 0px;
    margin-bottom: 3em;
    cursor: pointer;
    vertical-align: top;
}

.bigBookingButton > div {
    display: inline-block;
    vertical-align: middle;
    padding: 5px;
}

.ultraWidth>div:nth-child(2){
    width: 85%;
}

.bigBookingButton .mainText {
    font-size: 18px
}

.bigBookingButton .subText {
    font-size: 12px
}